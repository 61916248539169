import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Container } from '../components/container'

const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
	margin: 0;
	padding: 0;
`

export default () => (
  <Container>
    <Helmet>
      <title>adroit.ventures</title>
    </Helmet>
    <HomeContainer>
      <h1>adroit.ventures</h1>
    </HomeContainer>
  </Container>
)
